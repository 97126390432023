<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} other contract<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="item.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">{{ labels['active'] }}</small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'researcher.administration.other-contracts.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'OtherContracts')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="item" />

            <div class="card">
              <div
                v-b-toggle="`collapseContent`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseContent`"
                visible
              >
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="mb-1"> <!-- hacer crud -->
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.contract_with_institution'] }}</label>
                          <div class="pt-1">
                            <div class="form-check form-check-inline">
                              <input
                                id="inlineRadio1"
                                v-model="item.university_contract"
                                :value="true"
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions3"
                              >
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                id="inlineRadio2"
                                v-model="item.university_contract"
                                :value="false"
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions3"
                              >
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="loggedUser.roles.includes('super-admin')"
                        class="col-sm-6"
                      >
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.status'] }}</label>
                          <v-select
                            v-model="item.status"
                            label="status"
                            :options="statuses"
                            :get-option-key="option => option.id"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="mb-1"
                        >
                          <label
                            for=""
                            class="form-label d-block"
                          >{{ labels['content.contract_with_institution'] }} {{ item.university_contract ? '(only university)' : '' }} <a
                            v-if="!item.university_contract"
                            class="float-end text-danger"
                            @click="toggleInstitution"
                          ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a></label>
                          <v-select
                            v-if="!manualInstitution"
                            v-model="item.institution"
                            label="name"
                            :disabled="item.university_contract"
                            :clearable="false"
                            :options="hosts"
                            :get-option-key="option => option.id"
                            @search="onSearch({ name: $event, university: item.university_contract }, 'institutions/filter')"
                          />
                          <input
                            v-if="manualInstitution"
                            v-model="item.institution_name"
                            type="text"
                            class="form-control"
                            placeholder="Write host name"
                          >
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-1"> <!-- hacer crud -->
                          <label
                            for=""
                            class="form-label d-block"
                          >{{ labels['content.type'] }} <a
                            class="float-end text-danger"
                            @click="toggleContract"
                          ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a></label>
                          <v-select
                            v-if="!manualType"
                            v-model="item.type"
                            label="name"
                            :clearable="false"
                            :options="types"
                            :get-option-key="option => option.id"
                            @search="onSearch({ name: $event }, 'typeContracts/filter')"
                          />
                          <input
                            v-if="manualType"
                            v-model="item.contract_type_name"
                            type="text"
                            class="form-control"
                            placeholder="Write type of contract"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.description'] }}</label>
                          <quill-editor v-model="item.description" />
                        </div>
                      </div>

                    </div>
                    <hr>
                    <h4>Dates of contract</h4>
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.begin_date'] }}</label>
                          <date-picker
                            v-model="item.begin_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.end_date'] }}</label>
                          <date-picker
                            v-model="item.end_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div
                      v-if="loggedUser.roles.includes('super-admin')"
                      class="row d-flex align-items-end"
                    >
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.plurioccupation'] }}</label>
                          <div class="pt-1">
                            <div class="form-check form-check-inline">
                              <input
                                id="inlineRadio1"
                                v-model="item.plurioccupation"
                                :value="false"
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                              >
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >No</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                id="inlineRadio2"
                                v-model="item.plurioccupation"
                                :value="true"
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                              >
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >Yes</label>
                            </div>
                            <label
                              v-if="item.plurioccupation"
                              class="me-1"
                            >{{ labels['content.date'] }}</label>
                            <div
                              v-if="item.plurioccupation"
                              id="pluri-yes"
                              class="form-check-inline"
                            > <!-- si seleccionan yes -->
                              <date-picker
                                v-model="item.plurioccupation_date"
                                format="D MMM Y"
                                value-type="format"
                                class="w-100"
                              />
                            </div>
                            <div
                              v-if="item.plurioccupation"
                              class="mb-1"
                            >
                              <label
                                for=""
                                class="form-label"
                              >{{ labels['content.comments'] }}</label>
                              <textarea
                                v-model="item.plurioccupation_comments"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.compatibility'] }}</label>
                          <div class="pt-1">
                            <div class="form-check form-check-inline">
                              <input
                                id="inlineRadio1"
                                v-model="item.compatibility"
                                :value="false"
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions2"
                              >
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >No</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                id="inlineRadio2"
                                v-model="item.compatibility"
                                :value="true"
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions2"
                              >
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >Yes</label>
                            </div>
                            <label
                              v-if="item.compatibility"
                              class="me-1"
                            >{{ labels['content.date'] }}</label>
                            <div
                              v-if="item.compatibility"
                              id="pluri-yes"
                              class="form-check-inline"
                            > <!-- si seleccionan yes -->
                              <date-picker
                                v-model="item.compatibility_date"
                                format="D MMM Y"
                                value-type="format"
                                class="w-100"
                              />
                            </div>
                            <div
                              v-if="item.compatibility"
                              class="mb-1"
                            >
                              <label
                                for=""
                                class="form-label"
                              >{{ labels['content.comments'] }}</label>
                              <textarea
                                v-model="item.compatibility_comments"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </b-collapse>
            </div>

            <div
              v-if="!item.icrea_remarks_empty || loggedUser.roles.includes('super-admin')"
              class="card"
            >
              <div
                v-b-toggle="`collapseIcreaRemark`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">ICREA's remarks</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseIcreaRemark`"
                :visible="!item.icrea_remarks_empty || loggedUser.roles.includes('super-admin') ? true : false"
              >
                <div class="card-content"> <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labelsIcreaRemarks['content'] }}</label>
                          <quill-editor
                            v-if="loggedUser.roles.includes('super-admin')"
                            v-model="item.icrea_remarks"
                          />
                          <div
                            v-else
                            v-html="item.icrea_remarks"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <AddFile
              v-if="item.files_"
              :id="10"
              :title="'Related documents'"
              :type="7"
              :files="item.files_"
            />

            <InternalNotes
              :notes="item.notes"
              @saveNewNote="saveNewNote"
            />

            <AttachedForm
              :type="'OtherContracts'"
              :model-id="otherContractsId"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import AddFile from '../../../admin/senior-call/components/AddFile.vue'
import SearchResearcher from '../../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import AttachedForm from '../../../forms/partials/AttachedForm.vue'

export default {
  components: {
    AddFile,
    SearchResearcher,
    InternalNotes,
    vSelect,
    DatePicker,
    BCollapse,
    AttachedForm,
  },
  data() {
    return {
      otherContractsId: this.$route.params.id,
      sending: false,
      errors: '',
      manualInstitution: false,
      manualType: false,
      labelsIcreaRemarks: [],
    }
  },
  computed: {
    ...mapGetters({
      item: 'otherContracts/item',
      hosts: 'institutions/institutions',
      types: 'typeContracts/items',
      statuses: 'status/statuses',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
      attached: 'requestForms/attached',
    }),
    universityContract() {
      return this.item.university_contract
    },
  },
  watch: {
    universityContract() {
      if (this.item.university_contract === true) {
        const host = this.item.user.hosts[0]
        this.item.institution = host
      } else {
        this.item.institution = false
      }
    },
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.icrea_remarks`)
      this.labelsIcreaRemarks = data.data
    } catch (e) {
      console.error(e)
    }

    this.$store.dispatch('sectionLabels/fetchLabels', 'administration.other_contracts')
    if (this.otherContractsId) {
      await this.$store.dispatch('otherContracts/fetchId', this.otherContractsId)
      this.manualInstitution = typeof this.item.institution_name === 'string'
      this.manualType = typeof this.item.contract_type_name === 'string'
    } else {
      await this.$store.dispatch('otherContracts/cleanType')
      // this.item.user = this.loggedUser
    }

    await this.$store.dispatch('status/filterStatus', 'Other contracts')
    if (!this.item.status) {
      const status = this.statuses.filter(e => e.status === 'Authorization required')[0]
      this.item.status = status
      this.$forceUpdate()
    }
  },
  methods: {
    async save() {
      this.sending = true
      this.item.attached_form = this.attached

      try {
        if (this.otherContractsId) {
          await this.$store.dispatch('otherContracts/update', { id: this.otherContractsId, data: this.item })
        } else {
          await this.$store.dispatch('otherContracts/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The contract has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.administration.other-contracts.index' })
        })
      } else {
        Vue.swal('Error on saving the documentation.', 'Please, fill up all the required fields', 'error')
      }

      this.sending = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    toggleInstitution() {
      this.item.institution = null
      this.item.institution_name = null
      this.manualInstitution = !this.manualInstitution
    },
    toggleContract() {
      this.item.type = null
      this.item.contract_type_name = null
      this.manualType = !this.manualType
    },
  },
}
</script>
